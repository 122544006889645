<template>
    <div>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>战队信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="100px"
                ref="queryFormRef"
            >
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择类型"
                                style="width: 100%"
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6">
                        <el-form-item label="赛程" prop="raceSchedule">
                            <el-select
                                placeholder="请选择"
                                @visible-change="changeRaceScheduleValue"
                                style="width: 100%"
                                v-model="queryForm.raceSchedule"
                                clearable
                            >
                                <el-option
                                    v-for="item in raceScheduleList"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6"
                        ><el-form-item label="省市区" prop="schoolArea">
                            <el-cascader
                                placeholder="请选择"
                                v-model="queryForm.schoolArea"
                                ref="schoolAreaRef"
                                :options="areasData"
                                style="width: 100%"
                                :props="{
                                    expandTrigger: 'hover',
                                    label: 'name',
                                    value: 'areaId',
                                    children: 'areas',
                                }"
                                @change="provincesHandleChange"
                                clearable
                            ></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.schoolId"
                                @change="schoolSelectChange"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="item in provincesSchoolList"
                                    :key="item.id"
                                    :label="item.schoolName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="领队电话" prop="leadTeacherUserPhone">
                            <el-input
                                placeholder="请输入"
                                clearable
                                v-model="queryForm.leadTeacherUserPhone"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="俱乐部组别" prop="group">
                            <el-select
                                style="width: 100%"
                                v-model="queryForm.group"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_itsoGroup"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="战队名称" prop="name">
                            <el-input
                                placeholder="请输入"
                                clearable
                                v-model="queryForm.name"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="签到状态" prop="signIn">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.signIn"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_signInStatus"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否参赛" prop="joinMatch">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.joinMatch"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_joinMatch"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="getDictDataList()"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" style="width: 100%" @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <span>战队信息列表</span>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-button
                        type="primary"
                        plain
                        icon="el-icon-download"
                        @click="exportTable()"
                        >批量导出</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="赛季" prop="season"> </el-table-column>
                <el-table-column label="战队名称" prop="name">
                </el-table-column>
                <!-- <el-table-column
          label="战队组别"
          prop="clubInfo.group"
          :formatter="groupFrmatter"
        ></el-table-column> -->
                <el-table-column label="领队老师" prop="name">
                    <template slot-scope="scope">
                        {{  scope.row.leadTeacherUser ? scope.row.leadTeacherUser.userName : '-' }}
                    </template>
                </el-table-column>
                <el-table-column label="领队老师手机号" prop="phone">
                    <template slot-scope="scope">
                        {{ scope.row.leadTeacherUser ? scope.row.leadTeacherUser.phone : '-' }}
                    </template>
                </el-table-column>
                <el-table-column label="俱乐部" prop="clubName">
                    <template slot-scope="scope">
                        {{ scope.row.clubInfo.clubName }}
                    </template>
                </el-table-column>
                <el-table-column label="俱乐部组别" prop="group">
                    <template slot-scope="scope">
                        <span>{{scope.row.group ? (scope.row.group === '0' ? '小学组' : (scope.row.group === '1' ? '初中组' : '高中组')) : '-'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="学校省市区" prop="clubInfo">
                    <template slot-scope="scope">
                        {{ scope.row.clubInfo.provinceName }}/{{
                            scope.row.clubInfo.cityName
                        }}/{{ scope.row.clubInfo.countyName }}
                    </template>
                </el-table-column>
                <el-table-column label="学校" prop="schoolName">
                    <template slot-scope="scope">
                        {{ scope.row.school.schoolName }}
                    </template>
                </el-table-column>
                <el-table-column label="是否签到">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.signIn === true"
                            size="mini"
                            >已签到</el-tag
                        >
                        <el-tag type="danger" v-else size="mini">未签到</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            :disabled="scope.row.signIn === true"
                            size="small"
                            @click="manualSignUpClicked(scope.row)"
                            >手动签到</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { getAllAreaList, getitsoTeamList, getSchoolListWithCity, changeTeamSignInState } from '@/http/api'
export default {
  props: ['raceSchedule'],
  data () {
    return {
      raceScheduleList: [],
      areasData: [],
      provincesSchoolList: [],
      dict_itsoGroup: this.$userInfo.dict_schoolGroup(),
      dict_joinMatch: this.$userInfo.dict_joinMatch(),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dataList: [],
      total: 0,
      dict_signInStatus: [
        {
          dictCode: '1',
          dictValue: 1,
          dictLabel: '已签到'
        }, {
          dictCode: '0',
          dictValue: 0,
          dictLabel: '未签到'
        }
      ],
      queryForm: {
        schoolId: null,
        pageNum: 1,
        pageSize: 10,
        raceSchedule: this.raceSchedule,
        joinMatch: null
      }
    }
  },
  created () {
    this.getAllAreaList()
    this.getRaceScheduleList()
  },
  methods: {
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 赛程选择校验
    changeRaceScheduleValue (state) {
      console.log(state)
      if (state === true && this.queryForm.season === '') {
        this.$message.error('请先选择赛季')
      }
    },
    // 批量导出
    exportTable () {
      var downLoadUrl = this.$env.baseIP + 'itsoteam/export'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', this.queryForm)
    },
    // 获取赛程
    getRaceScheduleList () {
      console.log('this.queryForm', this.raceSchedule)
      this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '2' }).then(data => {
        this.raceScheduleList = data
        this.getDictDataList()
      })
    },
    // 获取战队信息列表
    getDictDataList () {
      getitsoTeamList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 省市区切换事件
    provincesHandleChange (value) {
      if (!value) return
      this.provincesSchoolList = []
      this.queryForm.schoolName = ''
      this.queryForm.schoolId = ''
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校选择赋值schoolId
    schoolSelectChange (schoolid) {
      this.queryForm.schoolId = schoolid
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDictDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDictDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDictDataList()
    },
    // 手动签到点击事件
    manualSignUpClicked (rowData) {
      this.$confirm('是否确认修改该战队签到信息状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.modifyTeamSignUp(rowData)
      }).catch(() => { })
    },
    // 确认修改战队签到信息
    async modifyTeamSignUp (rowData) {
      changeTeamSignInState({ id: rowData.id, signIn: true }).then((res) => {
        this.$message.success('修改成功！')
        this.getDictDataList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    groupFrmatter (cellValue) {
      var temp = '-'
      for (let index = 0; index < this.dict_itsoGroup.length; index++) {
        const element = this.dict_itsoGroup[index]
        if (cellValue.clubInfo.group === element.dictValue) {
          temp = element.dictLabel
        }
      }
      return temp
    }
  }
}
</script>

<style>
</style>
